import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public isAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private http: HttpClient
  ) {
    this.checkAuthenticated();
  }

  checkAuthenticated(): string {
    const token = localStorage.getItem('token');
    if (token && !this.tokenExpired(token)) {
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
    return token;
  }

  isLoggedIn(): boolean {
    return this.isAuthenticated.value;
  }

  private tokenExpired(token: string): boolean {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }
}

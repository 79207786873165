import { Meta } from "./meta";

export class LoginUser{
    email:string;
    password:string;
}
export class LoginAnswer{
    data:TokenData
}
export class TokenData{
    token:string
    user:UserData
}
export class UserData{
    id:number
    external_id:string
    first_name:string
    last_name:string
    email:string
}
export class UserSingleAnswer{
    meta:Meta
    data:User
}

export class UserAnswer{
    meta:Meta
    data:User[]
}

export class User{
    id:number
    email:string
    first_name:string
    last_name:string
    timezone:string
    avatar:Avatar
    role:Role;
}
export class Role{
    id:number;
}
export class Avatar{
    data:AvatarData
}
export class AvatarData{
    full_url:string;
}
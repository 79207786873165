import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoginAnswer, LoginUser, UserSingleAnswer } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private httpClient:HttpClient) { }

  public login(user:LoginUser):Observable<LoginAnswer>{
    return this.httpClient.post<LoginAnswer>(environment.API_BASE+"/auth/authenticate",user);
  }
  public getMyUserData(token:string):Observable<UserSingleAnswer>{
    let httpHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<UserSingleAnswer>(environment.API_BASE+"/users/me?fields=*,avatar.*",{headers:httpHeaders}); //&fields=*,avatar.*
  }

  
  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment.prod';
import { ShouterPayment, ShouterPaymentAnswer } from '../model/shouter';
import { User, UserAnswer, UserSingleAnswer } from '../model/user';
import { ShouterReportService } from '../services/shouter-report.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  private employeeId: number;
  public user: User;
  public shouterPayments: ShouterPayment[];
  constructor(private userService: UserService, private router: Router, private shouterReportService:ShouterReportService) { }

  ngOnInit(): void {
    this.loadInfos();
  }
  private loadInfos() {
    let token = localStorage.getItem('token');
    this.userService.getMyUserData(token).subscribe((result: UserSingleAnswer) => {
      if (result.data.role.id != environment.SHOUTER_ROLE) {
        this.router.navigate(["/request"]);
      } else {
        
        this.user = result.data;
        this.employeeId = result.data.id;
        this.loadShouterReport();
      }
    }, error => {
      console.log(error);
    })
  }
  private loadShouterReport(){
    let token = localStorage.getItem('token');
    this.shouterReportService.getShouterReports(this.employeeId,token).subscribe((result:ShouterPaymentAnswer)=>{
      this.shouterPayments = result.data;
      this.calculateShoutEchoRatio();
    },  
    error=>{
      console.log(error);
    });
  }

  private calculateShoutEchoRatio(){
    this.shouterPayments.forEach((shouterPayment:ShouterPayment)=>{
      if(shouterPayment.echos==0 || shouterPayment.shouts==0){
        shouterPayment.shoutEchoRatio = 0;
        return;
      }
      let ratio = shouterPayment.echos/ shouterPayment.shouts;
      if(ratio>=1){
        ratio = 1;
      }
      shouterPayment.shoutEchoRatio = ratio;
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { LoginAnswer, LoginUser } from '../model/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public loginUser:LoginUser;
  constructor(private userService:UserService,
    private router:Router) { }

  ngOnInit(): void {
    this.loginUser = new LoginUser();
  }

  public login(){
    let loginUser = new LoginUser();
    loginUser.email = this.loginUser.email + environment.EMAIL;
    loginUser.password = this.loginUser.password;
    console.log(loginUser);
    this.userService.login(loginUser).subscribe((result:LoginAnswer) =>{
      this.parseLoginData(result);
      this.router.navigate(["/"]);
    }, error =>{
      //TODO: Error handling
      console.log(error);
    })
  }

  private parseLoginData(loginData:LoginAnswer){
    localStorage.setItem('token', loginData.data.token);
    localStorage.setItem('user_id', loginData.data.user.id.toString());
  }
  private showError(error:string){
    alert(error);
  }

}

<div class="card text-center">
    <div class="card-header">
      Sorry...
    </div>
    <div class="card-body">
      <h5 class="card-title">We are currently not open for new applications</h5>
      <p class="card-text">As soon as new applications are activated, you can apply for them.</p>
      <a href="https://shouts.ch" class="btn btn-primary">Visit Shouts</a>
    </div>
    <div class="card-footer text-muted">
      Last updated: 30.05.2021
    </div>
  </div>
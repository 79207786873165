import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { ShouterPaymentAnswer, ShouterPaymentOneAnswer } from '../model/shouter';

@Injectable({
  providedIn: 'root'
})
export class ShouterReportService {

  constructor(private httpClient:HttpClient) { }

  public getShouterReports(employeeId:number,token:string):Observable<ShouterPaymentAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<ShouterPaymentAnswer>(environment.API_BASE+"/items/shouter_payment?filter[shouter][eq]="+employeeId,{headers: authHeaders});
  }
  public getShouterReport(reportId:number,token:string):Observable<ShouterPaymentOneAnswer>{
    let authHeaders = this.getAuthorizationHeader(token);
    return this.httpClient.get<ShouterPaymentOneAnswer>(environment.API_BASE+"/items/shouter_payment/"+reportId,{headers: authHeaders});
  }

  private getAuthorizationHeader(token): HttpHeaders {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    });
  }
}
 
<div class="sidenav">
    <div class="login-main-text">
       <h2>Shouts<br> Admin</h2>
       <p>Login from here to access.</p>
    </div>
 </div>
 <div class="main">
    <div class="col-md-6 col-sm-12">
       <div class="login-form">
          <form>
             <div class="form-group">
                <label>Username</label>
                <input type="text" class="form-control" placeholder="Username" [(ngModel)]="loginUser.email" name="email">
             </div>
             <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" placeholder="Password" [(ngModel)]="loginUser.password" name="password">
             </div><br/>
             <button type="button" class="btn btn-login" (click)="login();">Login</button>
          </form>
       </div>
    </div>
 </div>